<template>
  <Modal
    :open="modalOpen"
    :can-close="false"
  >
    <div class="border-b-2 bg-white">
      <div class="px-6 pb-6">
        <h3 class="font-medium text-lg">
          Sorry
        </h3>
      </div>
    </div>

    <div class="p-6">
      <p class="mb-6">
        Sorry, an error occurred.
      </p>
      <p
        v-if="errorMessage"
        class="mb-6"
      >
        {{ errorMessage }}
      </p>
      <BaseButton
        :to="{ name : 'settings-plan' }"
        variant="gray"
      >
        Back to Settings
      </BaseButton>
    </div>
  </Modal>
</template>

<script>
import Modal from '@components/Modal'

export default {
  components: {
    Modal
  },

  props: {
    modalOpen: {
      type: Boolean,
      default: false
    },

    errorMessage: {
      type: String,
      default: ''
    }
  }
}
</script>
