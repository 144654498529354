<template>
  <Modal
    :open="modalOpen"
    :can-close="false"
  >
    <div class="border-b-2 bg-white">
      <div class="flex items-center px-6 pb-6">
        <Icon
          width="42px"
          height="42px"
          view-box="0 0 80 80"
          class="mr-2"
        >
          <Celebration />
        </Icon>
        <h3 class="font-medium text-lg">
          Payment confirmed
        </h3>
      </div>
    </div>

    <div class="p-6">
      <p
        v-if="attemptsLimit > 0"
        class="mb-6"
      >
        You now have <strong class="text-lg">{{ attemptsLimit }}</strong> test attempts.
      </p>
      <p
        v-else
        class="mb-6"
      >
        Your plan has been updated.
      </p>
      <BaseButton
        :to="{ name : 'client-assessments-list' }"
      >
        Get started
      </BaseButton>
    </div>
  </Modal>
</template>

<script>
import Modal from '@components/Modal'
import Icon from '@components/Icons/Icon'
import Celebration from '@components/Icons/Celebration'

export default {
  components: {
    Modal,
    Icon,
    Celebration
  },

  props: {
    modalOpen: {
      type: Boolean,
      default: false
    },

    attemptsLimit: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
