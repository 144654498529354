import { pickedApiRequest } from './request'

export default {
  /**
   * Billing data
   *
   * @param {string} organisationId
   * @return {Array}
   */
  billing (organisationId) {
    return pickedApiRequest.get('assessment/organisations/' + organisationId + '/billing')
      .then(response => {
        return response.data
      })
  },

  /**
   * Subscription option
   *
   * @param {string} organisationId
   * @param {string} optionRef
   * @return {Array}
   */
  subscriptionOption (organisationId, optionRef) {
    return pickedApiRequest.get('assessment/organisations/' + organisationId + '/subscription-options/' + optionRef)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Invoices
   *
   * @param {string} organisationId
   * @return {Array}
   */
  invoices (organisationId) {
    return pickedApiRequest.get('assessment/organisations/' + organisationId + '/invoices')
      .then(response => {
        return response.data
      })
  },

  /**
   * Usage
   *
   * @param {string} organisationId
   * @return {Array}
   */
  usage (organisationId) {
    return pickedApiRequest.get('assessment/organisations/' + organisationId + '/usage')
      .then(response => {
        return response.data
      })
  },

  /**
   * Preview checkout
   *
   * @param {string} organisationId
   * @param {Object} payload
   * @return {Array}
   */
  preview (organisationId, payload) {
    return pickedApiRequest.post('assessment/organisations/' + organisationId + '/preview', payload)
      .then(response => {
        return response.data
      })
  }
}
