<template>
  <div>
    <div v-show="plans && plan">
      <SettingsNavigation />

      <div class="max-w-screen-xl mx-auto sm:w-1/2 px-6">
        <BaseCard class="my-6">
          <BaseCardHeader
            bg="bg-green-500"
            class="mb-6"
          >
            <template slot="icon">
              <CreditCard />
            </template>
            Billing
          </BaseCardHeader>

          <div>
            <div
              v-if="billing && billing.card"
              class="flex justify-between"
            >
              <div>
                <span class="text-xs bg-gray-600 rounded-sm p-1 mr-2 text-white">{{ billing.card.type }}</span>
                <span>
                  <span class="text-gray-300 text-sm">&bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull;</span>
                  {{ billing.card.lastFour }}
                </span>
              </div>
              <router-link
                :to="{ name: 'settings-billing' }"
                class="text-gray-500 underline hover:text-secondary duration-150"
              >
                Update
              </router-link>
            </div>
            <div v-show="!billing || !billing.card">
              <form
                ref="form"
                class="text-sm mt-8"
                novalidate
                @submit.prevent
              >
                <div>
                  <label class="block">
                    <span class="text-gray-800">Name on card</span>
                    <input
                      v-model="cardholderName"
                      placeholder="Name on card"
                      class="form-input mt-1 text-sm block w-full h-12"
                    >
                  </label>
                </div>

                <label class="block mt-6">
                  <span class="text-gray-800">Card details</span>
                  <div
                    id="card"
                    class="form-input mt-1 text-sm block w-full text-gray-500 px-2 py-4"
                  ></div>
                  <ErrorsInline v-if="cardError">
                    <span>{{ cardError }}</span>
                  </ErrorsInline>
                </label>
              </form>
            </div>
          </div>
          <div v-if="!billing">
            <Loader />
          </div>
        </BaseCard>

        <BaseCard class="my-6">
          <BaseCardHeader class="mb-6">
            <template slot="icon">
              <Receipt />
            </template>
            New plan
          </BaseCardHeader>

          <div
            v-if="plan"
            class="my-6"
          >
            <div class="flex items-center justify-between my-2">
              <div>
                <p
                  v-if="subscriptionOption.attemptsLimit"
                >
                  {{ subscriptionOption.attemptsLimit }} {{ subscriptionOption.attemptsInterval }}ly test attempts
                </p>
                <p
                  v-else-if="plan.tcAttemptsLimit"
                >
                  {{ plan.tcAttemptsLimit }} {{ plan.interval }}ly test attempts
                </p>
              </div>
              <div class="text-right">
                <span class="text-gray-800 font-semibold">{{ formatCurrency(subscriptionOption.amount, subscriptionOption.currency) }}</span>
                <span
                  v-if="showIntervals"
                  class="ml-1"
                >
                  per {{ plan.interval }}
                </span>
              </div>
            </div>

            <div
              v-if="subscriptionOption.discountAmount"
              class="flex items-center justify-between my-2"
            >
              <div>
                <p>Discount</p>
              </div>
              <div class="text-right">
                <span class="text-gray-800 font-semibold">– {{ formatCurrency(subscriptionOption.discountAmount, subscriptionOption.currency) }}</span>
                <span
                  v-if="showIntervals && subscriptionOption.discountDuration === 'forever'"
                  class="ml-1"
                >
                  per {{ plan.interval }}
                </span>
                <span
                  v-else-if="showIntervals && subscriptionOption.discountDuration === 'once'"
                  class="ml-1"
                >
                  first {{ plan.interval }}
                </span>
              </div>
            </div>
            <div
              v-if="subscriptionOption.discountAmount"
              class="flex items-center justify-between my-2 border-t pt-2"
            >
              <div>
                <p>Total</p>
              </div>
              <div class="text-right">
                <span class="text-gray-800 font-semibold">{{ formatCurrency(subscriptionOption.discountedAmount, subscriptionOption.currency) }}</span>
                <span
                  v-if="showIntervals && subscriptionOption.discountDuration === 'forever'"
                  class="ml-1"
                >
                  per {{ plan.interval }}
                </span>
                <span
                  v-else-if="showIntervals && subscriptionOption.discountDuration === 'once'"
                  class="ml-1"
                >
                  first {{ plan.interval }}
                </span>
              </div>
            </div>

            <div
              v-if="subscriptionOption.vatAmount"
              class="flex items-center justify-between my-2 border-t pt-2 text-gray-600"
            >
              <div>
                <p>VAT {{ subscriptionOption.vatPercentage }}%</p>
              </div>
              <div class="text-right">
                <span class="font-semibold">{{ formatCurrency(subscriptionOption.vatAmount, subscriptionOption.currency) }}</span>
                <span
                  v-if="showIntervals && subscriptionOption.discountDuration === 'forever'"
                  class="ml-1"
                >per {{ plan.interval }}</span>
                <span
                  v-else-if="showIntervals && subscriptionOption.discountDuration === 'once'"
                  class="ml-1"
                >
                  first {{ plan.interval }}
                </span>
              </div>
            </div>
          </div>

          <div>
            <BaseButton
              :loading="isProcessing"
              :disabled="!canConfirmPlan"
              class="w-full"
              @click="startPlan"
            >
              Start plan
            </BaseButton>
          </div>
        </BaseCard>
      </div>

      <SubscriptionOptionConfirmedModal
        v-if="plan"
        :modal-open="subscriptionConfirmed"
        :attempts-limit="attemptsLimit"
      />

      <SubscriptionErrorModal
        :modal-open="!!errorMessage"
        :error-message="errorMessage"
      />
    </div>
    <div v-show="!plans || !plan">
      <Loader />
    </div>
  </div>
</template>

<script>
import CreditCard from '@components/Icons/CreditCard'
import Loader from '@components/Loader'
import Receipt from '@components/Icons/Receipt'
import SettingsNavigation from '@components/Settings/SettingsNavigation'
import SubscriptionOptionConfirmedModal from '@components/Plans/SubscriptionOptionConfirmedModal'
import SubscriptionErrorModal from '@components/Plans/SubscriptionErrorModal'
import ErrorsInline from '@components/ErrorsInline'

import subscriptionsApi from '@api/subscriptions'
import billingApi from '@api/billing'
import { format, parseISO } from 'date-fns'
import { formatCurrencyAuto } from '@utils/currency'
import { mapGetters } from 'vuex'

// eslint-disable-next-line
const stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_TC)
const elements = stripe.elements()
const elementStyle = {
  base: {
    color: '#15151D',
    fontSize: '14px',
    fontFamily: 'Circular, "Helvetica Neue", Helvetica, Arial, sans-serif'
  },
  invalid: {
    color: '#E80404'
  }
}

export default {
  components: {
    CreditCard,
    Loader,
    Receipt,
    ErrorsInline,
    SettingsNavigation,
    SubscriptionOptionConfirmedModal,
    SubscriptionErrorModal
  },

  props: {
    optionRef: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      card: null,
      cardError: null,
      cardComplete: false,
      cardholderName: '',

      format,
      parseISO,

      subscriptionConfirmed: false,

      isProcessing: false,

      billing: null,
      subscriptionOption: null,

      defaultCurrency: 'GBP',
      defaultCountry: 'GB',
      defaultTaxCode: 'digital',
      showIntervals: false,

      errorMessage: '',
      supportEmail: process.env.VUE_APP_SUPPORT_EMAIL
    }
  },

  computed: {
    ...mapGetters({
      havePlansLoaded: 'plans/havePlansLoaded',
      haveSubscriptionsLoaded: 'subscriptions/haveSubscriptionsLoaded',
      plans: 'plans/tcPlans',
      organisationId: 'employers/organisationId',
      userId: 'employers/id'
    }),

    /**
     * @return {string}
     */
    planSlug() {
      if (!this.subscriptionOption) {
        return
      }
      return this.subscriptionOption.planSlug
    },

    /**
     * @return {Object}
     */
    plan() {
      if (!this.plans || !this.planSlug) {
        return
      }

      return this.plans.find(plan => plan.slug === this.planSlug)
    },

    /**
     * @return {Boolean}
     */
    canConfirmPlan() {
      return this.plan && ((this.billing && this.billing.card) || this.canSubmitCard)
    },

    /**
     * Decides if the user can submit the form
     *
     * @return {Boolean}
     */
    canSubmitCard() {
      return this.aCardFieldIsEmpty && this.cardComplete
    },

    /**
     * @return {Boolean}
     */
    aCardFieldIsEmpty() {
      return !!this.cardholderName
    },

    /**
     * Returns an object for extra verification from Stripe
     *
     * @return {Object}
     */
    stripeTokenData() {
      return {
        billing_details: {
          name: this.cardholderName
        }
      }
    },

    /**
     * @return {Number}
     */
    attemptsLimit() {
      if (this.subscriptionOption && this.subscriptionOption.attemptsLimit) {
        return parseInt(this.subscriptionOption.attemptsLimit)
      }
      if (this.plan && this.plan.tcAttemptsLimit) {
        return parseInt(this.plan.tcAttemptsLimit)
      }
      return 0
    }
  },

  created() {
    this.getBilling()
    this.getPlans()
    this.getSubscriptionOption()
  },

  mounted() {
    this.setUpStripe()
  },

  destroyed() {
    this.card.destroy('card')
  },

  methods: {
    /**
     * Fetch the plans if we need to get them
     */
    getPlans() {
      if (this.havePlansLoaded) {
        this.getSubscriptions()
        return
      }
      this.$store.dispatch('plans/getPlans')
        .then(() => {
          this.getSubscriptions()
        })
    },

    /**
     * Fetch the billing data
     */
    getBilling() {
      return billingApi.billing(this.organisationId)
        .then(response => {
          this.billing = response.data
        })
    },

    /**
     * Fetch the subscription Option data
     */
    getSubscriptionOption() {
      return billingApi.subscriptionOption(this.organisationId, this.optionRef)
        .then(data => {
          this.subscriptionOption = data
        })
    },

    /**
     * Triggered when the user updates their billing details
     */
    updateBillingComplete() {
      this.billing = null
      return billingApi.billing(this.organisationId)
        .then(response => {
          this.billing = response.data
        })
    },

    /**
     * Fetch the subscriptions
     */
    getSubscriptions() {
      if (this.haveSubscriptionsLoaded) {
        return
      }
      this.$store.dispatch('subscriptions/getSubscriptions')
    },

    /**
     * No active plan, start a new subscription
     */
    startPlan() {
      this.isProcessing = true

      this.submitCard()
        .then(() => {
          this.$store.dispatch('subscriptions/createSubscriptionOption', {
            planSlug: this.plan.slug,
            optionRef: this.optionRef
          })
            .then(action => {
              if (action.clientSecret) {
                return stripe
                  .confirmCardPayment(action.clientSecret)
                  .then(result => {
                    if (result.error) {
                      console.error('Could not confirm card payment')
                      this.errorMessage = result.error.message
                      return
                    }
                    if (result.paymentIntent.status === 'succeeded') {
                      this.subscriptionCardConfirmed()
                      return
                    }
                    console.log(result)
                  })
              } else if (action.requires === 'payment_method') {
                this.errorMessage = `Your payment method previously couldn’t be authorised. Please contact ${this.supportEmail} for further help.`
                return
              }
              this.subscriptionCardConfirmed()
            })
        })
    },

    /**
     * Card has been authed
     */
    subscriptionCardConfirmed() {
      if (this.$gtm) {
        this.$gtm.trackEvent({
          event: 'event',
          eventCategory: 'Subscription',
          eventAction: 'Started',
          eventLabel: this.planSlug
        })
      }

      return this.$store.dispatch('subscriptions/confrimSubscriptionOption', {
        optionRef: this.optionRef
      })
        .then(() => {
          return this.$store.dispatch('subscriptions/getSubscriptions')
            .then(() => {
              this.isProcessing = false
              this.subscriptionConfirmed = true
            })
        })
    },

    /**
     * Uses the pricing plan to work out the currency and formats
     *
     * Guy prefers that the currency is always displayed in the British English
     * format without pennies, such a £150
     *
     * @return {string}
     * @return {string}
     */
    formatCurrency(amount, currency) {
      if (!currency) {
        currency = this.defaultCurrency
      }
      return formatCurrencyAuto(amount / 100, currency)
    },

    /**
     * Sets the plan data
     */
    submitCard() {
      console.log('💳 Submit card to Stripe')

      return stripe.createPaymentMethod({
        type: 'card',
        card: this.card,
        billing_details: {
          name: this.cardholderName
        }
      })
        .then(result => {
          if (result.error) {
            console.error(result.error)
            return
          }
          console.log('💳 Save card response')
          // @TODO 2020-03-25 Replace with update card (create customer if missing)
          return subscriptionsApi
            .createCustomer(this.organisationId, this.userId, result.paymentMethod)
            .then(response => {
              this.$emit('updateComplete')
            })
        })
    },

    /**
     * Sets up Stripe Elements
     */
    setUpStripe() {
      this.card = elements.create('card', {
        style: elementStyle,
        iconStyle: 'solid'
      })
      this.card.mount('#card')

      this.listenForErrors()
    },

    /**
     * Listen for and store errors
     */
    listenForErrors() {
      this.card.addEventListener('change', ({ complete, error }) => {
        this.cardComplete = complete
        if (error) {
          this.cardError = error.message
          return
        }
        this.cardError = null
      })
    }
  }
}
</script>
