<template>
  <div class="w-full py-8">
    <SubscriptionOption
      :option-ref="optionRef"
    />
  </div>
</template>

<script>
import SubscriptionOption from '@components/Plans/SubscriptionOption'

export default {
  page: {
    title: 'Confirm plan'
  },

  components: {
    SubscriptionOption
  },

  computed: {
    /**
     * @return {string}
     */
    optionRef() {
      return this.$route.params.ref
    }
  }
}
</script>
